import React from 'react'
import { graphql } from 'gatsby'

const Footer = ({ pathname, dates }) => {

	// const handleLinkClick = target => {
	// 	if (pathname === '/') {
	// 		const targetEl = document.querySelector(`.Target-${target}`)
	// 		if (targetEl) {
	// 			smoothScrollTo(targetEl)
	// 		}
	// 	} else {
	// 		if (target === 'Tickets') {
	// 			const targetEl = document.querySelector(`.Target-${target}`)
	// 			if (targetEl) {
	// 				smoothScrollTo(targetEl)
	// 			}
	// 		} else {
	// 			navigate('/')
	// 			setTimeout(() => {
	// 				const targetEl = document.querySelector(`.Target-${target}`)
	// 				if (targetEl) {
	// 					smoothScrollTo(targetEl)
	// 				}
	// 			}, 1500)
	// 		}
	// 	}
	// }

	return (
		<footer className="Footer">
			<div className="Footer__Nav">
				<p className="Footer__Logo">Tech week</p>
				<ul className="Footer__Links Footer__Links__Home">
					{/* <li className="Footer__Link" onClick={() => handleLinkClick('About')} data-is-link>
						<span className="Footer__LinkLabel">
							About
						</span>
					</li>
					<li className="Footer__Link" onClick={() => handleLinkClick('Partners')} data-is-link>
						<span className="Footer__LinkLabel">
							Partners
						</span>
					</li>
					<li className="Footer__Link" onClick={() => handleLinkClick('Speakers')} data-is-link>
						<span className="Footer__LinkLabel">
							Speakers
						</span>
					</li> */}
					<li className="Footer__Link" data-is-link>
						{/* <Link to="/communitycalendar" className="Footer__LinkLabel">
							Community calendar
						</Link> */}
						<a className="Footer__LinkLabel" href="https://lu.ma/miamitechweek2024" target="_blank" rel="noreferrer">
							Community calendar
						</a>
					</li>
					<li className="Footer__Link" data-is-link>
						<a className="Footer__LinkLabel" href="/hackers">
              Hackers
						</a>
					</li>
					{/* <li className="Footer__Link" data-is-link>
						<a className="Footer__LinkLabel" href="https://twitter.com" target="_blank" rel="noreferrer">Twitter</a>
					</li> */}
				</ul>
        <ul className="Footer__Links Footer__Links__Hackers">
					<li className="Footer__Link" data-is-link>
						<a className="Footer__LinkLabel" href="https://lu.ma/miamitechweek2024" target="_blank" rel="noreferrer">
							Community calendar
						</a>
					</li>
					<li className="Footer__Link" data-is-link>
						<a className="Footer__LinkLabel" href="/hackers">
              Hackers
						</a>
					</li>
				</ul>
			</div>
			<div className="Footer__Content">
				<div className="Footer__ContentLeft">
					{/* <div className="Footer__Location">Miami <br/> Faena Forum</div> */}
					{/* <div className="Footer__Legals">
						<div className="Footer__Legal" data-is-link>Terms and conditions</div>
						<div className="Footer__Legal" data-is-link>Privacy Policy</div>
					</div> */}
					{/* <a className="Footer__LinkMobile" href="https://twitter.com" target="_blank" rel="noreferrer">Twitter</a> */}
				</div>
				<div className="Footer__ContentRight">
					{dates.text}
				</div>
			</div>
		</footer>
	)
}

export default Footer

export const query = graphql`
	fragment Footer on PrismicFooter {
		data {
      dates {
        text
      }
    }
	}
`